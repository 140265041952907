import React from "react";
import "./Popup.css";
import FontAwesome from "react-fontawesome";

export default function Popup(props) {
    return (
        <>
            {/* <div className="popup-button"> */}
                <button
                    type="button"
                    className="btn bg-primary popup-button"
                    onClick={props.onClickShow}
                >
                    <FontAwesome name="icon" className="fa-light fa-message text-white fs-2 mt-2" />
                </button>
            {/* </div> */}
            {props.isOpen && (
                <div className="container">
                    <div className="row">
                    <div className="col-md-4 col-sm-6 col-8 popup">
                            <div className="mt-2 p-2">
                                <p>
                                    Please fill out the form below and we will get back to you as soon
                                    as possible.
                                </p>
                            </div>
                            <div className="popup-form">
                                <form onSubmit={props.onSubmitClick}>
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Name*"
                                        className="w-100"
                                        valid="true"
                                        required
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Email*"
                                        className="mt-3 mb-3 w-100"
                                        valid="true"
                                        required
                                    />
                                    <textarea
                                        type="text"
                                        name="message"
                                        placeholder="Message*"
                                        className="w-100"
                                        valid="true"
                                        required
                                    />
                                    <button type="submit" className="btn btn-primary w-100 mt-3">
                                        Submit
                                    </button>
                                </form>
                            </div>
                    </div>
                </div>
                </div>
            )}
            {props.success && (
                <div className="container text-black">
                    <div className="row">
                        <div className="col-md-4 col-sm-6 col-8 popup1">
                            <div style={{marginTop:"150px"}}>
                            <p className="fs-5">Your message was sent successfully.</p>
                            <button className="btn btn-primary" onClick={props.onAgainSendClick}>Send Again</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
