import React from "react";
import "./Home.css";

export default function Home() {
  return (
    <>
      <div className="kiezen-bg">
        <div className="container text-black">
          <div className="row ">
            <div className=" col-lg-12 col-md-12 mt-5">
              <img src="Image/kiezenLogo-transformed.png" alt="company-logo" />
              <h1 className="kiezen1">Kiezen Soft Tech</h1>
              <h5 className="kiezen2 mt-4">
                Best web development, Software development, SEO and SMO, Digital
                Marketing services. We help you grow.
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid text-black who-we-are">
        <div className="row">
          <div className="col-md-6 mb-5">
            <img src="Image/about.png" alt="about" style={{ width: "100%" }} />
          </div>
          <div className="col-md-5 kiezen3">
            <h3 style={{ textAlign: "justify" }}>Who we are?</h3>
            <p style={{ textAlign: "justify", color: "#666" }}>
              <font size="+2" style={{ color: "orange" }}>
                Kiezen Soft Tech
              </font>
              &nbsp; is a leading software development company in Varanasi. Our
              main Motto has always been our satisfied and happy customers. With
              our excellent team and support we provide best services and best
              software to enhance your business and help you lead in your fields
              . We help you build your ideas with more creation and innovation .
              Our services include custom software development, online marketing
              , e-commerce platforms and many other leading technologies .
              Providing you with best custom software technologies. Custom
              software are developed keeping your business flow and strategy in
              mind . All the software development process is carried forward
              with your requirements and all tools that help you excel and grow.
            </p>
          </div>
        </div>
      </div>
      <div className="service-bg">
        <div className="container mt-5">
          <div className="row">
            <h2 className="why-choose">Why Choose Us</h2>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-6 service-row1">
              <div className="card card1 mb-2">
                <div className="why-icon">
                  <i className="fa fa-phone lg"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">24/7 CUSTOMER SERVICE</h5>
                  <p className="card-text mt-4" style={{ color: "#666" }}>
                    We provide our customers with 24/7 customer service . Our
                    team is always available to fix your issues.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 service-row3">
              <div className="card card2 mb-2">
                <div className="why-icon">
                  <i className="fa fa-users lg"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">GOOD TEAMWORK</h5>
                  <p className="card-text mt-4" style={{ color: "#666" }}>
                    Our team coordinates and innovates upon ideas in most
                    effective way to give you best.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 service-row2">
              <div className="card card3 mb-2">
                <div className="why-icon">
                  <i className="fa fa-cog lg"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">BEST CUSTOM SOFTWARE</h5>
                  <p className="card-text mt-4" style={{ color: "#666" }}>
                    We provide you with best custom software that are build keep
                    your work flow and strategies in mind.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 service-row4">
              <div className="card card4 mb-2">
                <div className="why-icon">
                  <i className="fa fa-money-bill-1 lg"></i>
                </div>
                <div className="card-body">
                  <h5 className="card-title">AFFORDABLE PRICE</h5>
                  <p className="card-text mt-4" style={{ color: "#666" }}>
                    We provide all services at very affordable and reasonable
                    price.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid message">
        <div className="row ">
          <h3
            className="fw-bold fs-3 message-heading"
            style={{
              textAlign: "justify",
              color: "darkorange",
              // marginLeft: "60px",
            }}
          >
            Message from the CEO
          </h3>
        </div>
        <div className="row mt-5">
          <div className="col-md-5">
            <img
              src="Image/sumit.png"
              alt="CEO"
              className="mt-5"
              style={{ width: "250px", height: "250px", borderRadius: "50%" }}
            />
          </div>
          <div className="col-md-7 mb-5">
            <h4 className="name">SUMIT KUMAR SRIVASTAVA</h4>
            <h5 className="post">( CEO & CO-FOUNDER )</h5>
            <p className="mt-5" style={{ color: "#666", textAlign: "justify" }}>
              <font size="+1" style={{ color: "orange" }}>
                Kiezen Soft Tech
              </font>
              &nbsp; is a leading force of consulting and contracting in
              information technology, providing technology services. we have
              created a prime environment for innovation and diversity.
              <br />
              These are not just words to us, but actions that make us the best
              at what we do.
              <font size="+1" style={{ color: "orange" }}>
                Kiezen Soft Tech
              </font>
              &nbsp; founded by group of experienced group of IT Experts,
              software technocrats has established a reputation in the
              government and commercial sectors as respected leader and trusted
              resource for exceptional it support services, IT consulting
              staffing services. We guaranteed to meet and exceed our client’s
              goal and expectations.
              <font size="+1" style={{ color: "orange" }}>
                Kiezen Soft Tech
              </font>
              &nbsp; is proud to be one of the best providers of Software
              services.
            </p>
          </div>
        </div>
      </div>

      <div className="kiezen-missions">
      <div className="container">
        <div className="row">
          <h2 className="missions">Our Missions</h2>
        </div>
        <div className="row">
          <div className="col-lg-7">
          <p className="missions-para">
            Our mission is to become the best IT Service provider company and
            give best experience to the users of our products and to provide
            best custom softwares that fits our clients flow. And Apart from
            giving the best of us we also focus on helping our customer develop
            and rise with the help of innovative and imaginative solutions. We
            aim to convert your imagination into reality with the help of our
            creative team. Our main goal is to provide high quality solutions
            that are secure and that too at a reasonable cost. For us the
            loyalty to our customer and comfort for them comes first. Simply put
            together our main motto is to simplify your problem and help your
            accomplish your goals and mission. Our Organisational goal include
            accomplishment of individual development of our team members and
            staff. To gain a perfect coordination between team and to develop
            your minds and imagination with time so that we help our client
            attain the latest technologies trending.
          </p>
          </div>
          <div className="col-lg-5">
            <img  src="Image/our-mission.png" alt="mission-logo"/>
          </div>
        </div>
      </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <h2 className="kiezen-services">Services</h2>
        </div>

        <div className="row mt-5 service-card">
          <div className="col-md-4">
            <div className="card">
              <img
                className="card-img-top"
                // style={{ height: "212px" }}
                src="Image/web-development.jpg"
                alt="software"
              />
            </div>
            <div className="mt-2 service-anim">
                <h5 className="text-black">Web Application Development</h5>
              </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="card">
              <img
                className="card-img-top"
                // style={{ height: "218px" }}
                src="Image/graphic.jpg"
                alt="graphic designing"
              />
            </div>
            <div className="mt-3 service-anim">
                <h5 className="text-black">Graphic Designing</h5>
              </div>
          </div>
          
          <div className="col-md-4">
            <div className="card">
              <img
                className="card-img-top"
                // style={{ height: "212px" }}
                src="Image/ecommerce.jpg"
                alt="E-commerce"
              />
            </div>
            <div className="mt-3 service-anim">
                <h5 className="text-black">E-Commerce</h5>
              </div>
          </div>
        </div>
        <div className="row mt-5 service-card">
          <div className="col-md-4">
            <div className="card">
              <img
                className="card-img-top"
                src="Image/responsive-web-design.png"
                alt="website development"
              />
            </div>
            <div className="mt-2 service-anim">
                <h5 className="text-black">Website Development</h5>
              </div>
          </div>
          <div className="col-md-4 mt-4">
            <div className="card">
              <img
                className="card-img-top"
                //  style={{ height: "218px" }}
                src="Image/software-development.jpg"
                alt="software development"
              />
            </div>
            <div className="mt-4 service-anim">
                <h5 className="text-black">Software Development</h5>
              </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <img
                className="card-img-top"
                src="Image/digital-marketing.jpg"
                alt="marketing"
              />
            </div>
            <div className="mt-2 service-anim">
                <h5 className="text-black ">Digital Marketing</h5>
              </div>
          </div>
        </div>
      </div>
      
    </>
  );
}
