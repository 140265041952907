import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="container ">
        <div className="row footer1">
          <div className="col-lg-3 col-md-3 col-sm-12">
            <h6>About Us</h6>
            <hr />
            <p style={{ textAlign: "justify" }}>
              <font size="+1" style={{ color: "orange" }}>
                Kiezen Soft Tech
              </font>
              &nbsp; is a leading force of consulting and contracting in
              information technology, providing technology services. we have
              created a prime environment for innovation and diversity.
              <br />
            </p>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <h6>Quick Links</h6>
            <hr />
            <Link to="/">Home</Link>
            <br />
            <Link to="/products">Products</Link>
            <br />
            <Link to="/services">Services</Link>
            <br />
            <Link to="/contact">Contact</Link>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <h6>Contact Address</h6>
            <hr />
            <span style={{ fontSize: "13px" }}>
              <i className="fa-solid fa-location"></i> KIEZEN SOFT TECH
            </span>
            <br />
            <span style={{ fontSize: "13px" }}>
              Shahi Apartment 3rd Floor, Mahmoorganj, Varanasi-221010
            </span>
            <br />
            <span>
              <i className="fa fa-phone"></i> +91-9565566662
            </span>
            <br />
            <span>
              <i className="fa-regular fa-envelope"></i>&nbsp;
              sumit.iti.sri@gmail.com
            </span>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <h6>Our Services</h6>
            <hr />
            <ul className="our-service">
              <li>Software Development</li>
              <li>Website Development</li>
              <li>E-Commerce</li>
              <li>Web Application Development</li>
              <li>Graphic Designing</li>
            </ul>
          </div>
        </div>
        <div className="row mt-3">
          <div
            className="col-lg-12 col-md-12 col-sm-12 text-center"
            style={{ color: "white", fontSize: "15px" }}
          >
            <div className="card-footer">
              <span>Copyright @ Kiezen Soft Tech</span>
              <br />
              <span>All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
