import React from "react";
import "./Career.css";
import FontAwesome from "react-fontawesome";

export default function Career() {
  return (
    <div className="container-fluid g-0">
      <div className="career-bg">
        <div className="container">
          <div className="row">
            <h2 className="career-heading">Careers</h2>
            <p className="text-white">Dedication, Hardwork and curosity.</p>
          </div>
        </div>
      </div>
      <div className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-7 about mt-5">
            <h3 style={{ textAlign: "justify" }}>
              Software Development Career
            </h3>
            <p style={{ textAlign: "justify", color: "black" }}>
              The main goal of a software developer to make a software that
              satisfies and fulfil all clients needs. A software developer must
              look forward for not only big opportunities but also achieve
              knowledge about latest of the field. A good software developer
              goes to the root of the concepts and all the resources for the
              client. He must first identify the flow and the core of the work
              and functionality.
              <br />
              <br /> Task of a software developer not only include the
              development but also require various skills like good analytics,
              evaluation skills, effective communication, detailed approach to
              software design and development. A software developer takes daily
              inspiration and innovative ideas to manipulate lines of code in
              order to create something that client imagined . A software
              developer makes imaginations a reality.
              <br />
              <br /> A developer looks for the working of the program designed
              but also looks forward for the security for the user . After the
              completion of task and delivery of product to the customer .
              Testing and updating are included in the responsibilities of the
              developer.
            </p>
          </div>
          <div className="col-md-5" style={{marginTop:"100px"}}>
            <img src="Image/history.png" alt="career" className="w-100" />
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-md-4 text-black mt-5">
            <h3
              style={{ textAlign: "justify", fontWeight: "bold" }}
              className="text-center"
            >
              Required Skills
            </h3>
            <div className="required-skills mt-4">
              <ul>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Fluent and effective communication skills
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Good logics for software
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Good Analytic skills
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Must know project management
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Teamwork
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Ability to work independently
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Seo and good designing skills
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 text-black mt-5">
            <h3
              style={{ textAlign: "justify", fontWeight: "bold" }}
              className="text-center"
            >
              Responsibilities
            </h3>
            <div className="required-skills mt-4">
              <ul>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Fluent and effective communication skills
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Good logics for software
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Good Analytic skills
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Must know project management
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Teamwork
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Ability to work independently
                </li>
                <li>
                  <FontAwesome
                    name="icon"
                    className="fa-regular fa-hand-point-right text-primary"
                  />
                  &nbsp;&nbsp;Seo and good designing skills
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-content">
              <form>
                <fieldset className="form-group">
                  <legend className="text-center text-black"> Apply Now</legend>
                </fieldset>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Your Name"
                    valid="true"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="Date"
                    placeholder="Date of Birth"
                    name="DOB"
                    autoComplete="off"
                    className="form-control"
                    valid="true"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    name="email"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Email Address"
                    valid="true"
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="tel"
                    name="contact"
                    autoComplete="off"
                    className="form-control"
                    placeholder="Your Contact"
                    valid="true"
                    required
                  />
                </div>
                <div className="form-group">
                  <select className=" w-100 p-2 rounded-2">
                    <option value="web-developer"> Web-Developer </option>
                    <option value="app-developer"> App-Developer </option>
                    <option value="web-designer"> Web-Designer </option>
                    <option value="digital-marketing">
                      Digital -Marketing
                    </option>
                    <option value="graphic-designer">
                      Graphic -Designer
                    </option>
                    <option value="marketing"> Marketing </option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    type="File"
                    name="applicant_cv"
                    autoComplete="off"
                    className="form-control"
                    placeholder="upload CV"
                    valid="true"
                    required
                  />
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-primary">
                    Apply
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
