import React from "react";
import "./About.css";

export default function About() {
  return (
    <div className="container-fluid g-0">
      <div className="about-bg">
        <div className="container">
          <div className="row">
            <h2 className="about-heading">About Us</h2>
            <p className='text-white'>Every Business has a story.Here's our.</p>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 about">
            <h3 style={{ textAlign: "justify" }}>Who we are?</h3>
            <p style={{ textAlign: "justify", color: "#666" }}>
              <font size="+2" style={{ color: "orange" }}>
                Kiezen Soft Tech
              </font>
              &nbsp; is a leading software development company in Varanasi. Our
              main Motto has always been our satisfied and happy customers. With
              our excellent team and support we provide best services and best
              software to enhance your business and help you lead in your
              fields. We help you build your ideas with more creation and
              innovation . Our services include custom software development,
              online marketing, e-commerce platforms and many other leading
              technologies . Providing you with best custom software
              technologies. Custom software are developed keeping your business
              flow and strategy in mind . All the software development process
              is carried forward with your requirements and all tools that help
              you excel and grow.
            </p>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
            <img src="Image/about.png" className="w-100" alt="who we are" />
          </div>
        </div>
      </div>

      <div className="about-us-bg">
        <div className="container mb-5">
          <div className="row mt-5 ms-lg-2 about ">
            <h3 style={{ textAlign: "justify", color: "white" }}>Why Choose Us?</h3>
          </div>

          <div className="row mt-3">
            <div className="col-md-3 col-sm-6">
              <div className="card-box">
                <img
                  src="Image/responsive_desgn.png"
                  alt="responsive_desgn"
                  className="w-50"
                />
                <p className="about-heads">Responsive Designs</p>
                <p>Creative and responsive designs and layouts.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card-box">
                <img
                  src="Image/latest_tech.png"
                  alt="latest_tech"
                  className="w-50"
                />
                <p className="about-heads">Latest Technologies.</p>
                <p>Latest, fast and SEO friendly techniques used .</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card-box">
                <img src="Image/24x7.png" alt="24x7" className="w-50" />
                <p className="about-heads">24/7 Support</p>
                <p>24/7 customer Support for all troubles.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card-box">
                <img src="Image/organised.png" alt="organised" className="w-50" />
                <p className="about-heads">Organized approaches</p>
                <p>Well organized and structured work strategies.</p>
              </div>
            </div>
          </div>
          <div className="row last-row">
            <div className="col-md-3 col-sm-6">
              <div className="card-box">
                <img src="Image/time.png" alt="organised" className="w-50" />
                <p className="about-heads">Time saving Solutions</p>
                <p>Effective and time saving solutions.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card-box">
                <img src="Image/managment.png" alt="organised" className="w-50" />
                <p className="about-heads">Spetacular Management</p>
                <p>Understanding and well experienced management.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card-box">
                <img src="Image/new_idea.png" alt="organised" className="w-50" />
                <p className="about-heads">New Innovations</p>
                <p>Turning your imagination into reality.</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6">
              <div className="card-box">
                <img src="Image/economic.png" alt="organised" className="w-50" />
                <p className="about-heads">Economic Solutions</p>
                <p>Cost effective solutions for all businesses.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row mb-5">
          <div className="col-lg-6 col-md-6 col-sm-12 about">
            <h3 style={{ textAlign: "justify" }}>Our Projects</h3>
          </div>
        </div>
        <div className="row text-black">
          <div className="col-md-6">
            <div className="project-card">
              <h3 className="fw-bold vaish">Vaishekta</h3>
              <img src="Image/vaishekta.webp" style={{ filter: "grayscale(15)" }} alt="skyRead" className="w-25 rounded-3" />
              <p className="pt-3">India's Largest Vaish Community App</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="project-card">
              <h3 className="fw-bold">SkyReadNovel</h3>
              <img src="Image/skyread.webp" alt="skyRead" className="w-25 rounded-3" />
              <p className="pt-3">Sky Read Digital Pvt Ltd new Book and Novel related App</p>
            </div>
          </div>
        </div>
        <div className="row mt-4 text-black">
          <div className="col-md-6">
            <div className="project-card">
              <h3 className="fw-bold">GGPS School</h3>
              <img src="Image/gyan-ganga.jpg" alt="skyRead" className="w-25 rounded-3" />
              <p className="pt-3 pb-2">Gyan Ganga Public School</p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="project-card">
              <h3 className="fw-bold">Vandhanam Banquet</h3>
              <img src="Image/vandhanam.jpeg" alt="skyRead" className="w-25 rounded-3" />
              <p className="pt-3">Vandhanam Multipurpose Hall</p>
            </div>
          </div>
        </div>
        <div className="row mt-4 mb-5 text-black">
          <div className="col-md-6">
            <div className="project-card">
              <h3 className="fw-bold">Shri Yoga Mandir</h3>
              <img src="Image/shree-yoga-mandir.jpg" alt="skyRead" className="w-25 rounded-3" />
              <p className="pt-3">
                “Shri Yoga Mandir” mean the temple of yoga, where people could
                stay and learn yoga in an ideal atmosphere like an ashram of
                Rishi (Sacred man).
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <div className="project-card">
              <h3 className="fw-bold">ISSA</h3>
              <img src="Image/issa.jpg" alt="skyRead" className="w-25 rounded-3" />
              <p className="pt-4">
                INDIAN SOCIAL SCIENCE ACADEMY (ISSA) is a national Academy in
                the field of social sciences.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ marginTop: "70px" }}>
        <div className="row mt-5">
          <div className="col-lg-6 col-md-6 col-sm-12 mt-5">
            <img
              src="Image/our-mission.png"
              className="w-100"
              alt="our motto"
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 about">
            <h3 style={{ textAlign: "justify" }}>Our Motto</h3>
            <p className="mt-5" style={{ textAlign: "justify", color: "#666" }}>
              Our mission is to become the best IT Service provider company and
              give best experience to the users of our products and to provide
              best custom softwares that fits our clients flow. And Apart from
              giving the best of us we also focus on helping our customer
              develop and rise with the help of innovative and imaginative
              solutions. We aim to convert your imagination into reality with
              the help of our creative team. Our main goal is to provide high
              quality solutions that are secure and that too at a reasonable
              cost. For us the loyalty to our customer and comfort for them
              comes first. Simply put together our main motto is to simplify
              your problem and help your accomplish your goals and mission. Our
              Organisational goal include accomplishment of individual
              development of our team members and staff. To gain a perfect
              coordination between team and to develop your minds and
              imagination with time so that we help our client attain the latest
              technologies trending.
            </p>
          </div>
        </div>
      </div>

      <div className="about-bg mt-4"style={{paddingBottom:"70px"}}>
        <div className="container">
          <div className="row text-black">
            <div className="col-md-12 about mt-5">
              <h3 style={{ textAlign: "justify",color:"white" }}>Our Clients</h3>
            </div>
            <div className="row mt-3">
              <div className="col-md-3">
                <div className="clients-box">
                  <img src="Image/citykart.png" className="w-100" style={{ height: "150px" }} alt="citykart" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="clients-box">
                  <img src="Image/skyread.jpg" className="w-100 rounded-3" style={{ height: "150px" }} alt="skyread" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="clients-box">
                  <img src="Image/vaishekta-logo.png" className="w-100" style={{ height: "150px" }} alt="citykart" />
                </div>
              </div>
              <div className="col-md-3">
                <div className="clients-box">
                  <img src="Image/exotic-wine.png" className="w-100 rounded-3" style={{ height: "150px" }} alt="exotic-wine" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
