import React from "react";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="container-fluid g-0 contact-main">
      <div className="contact-bg">
        <div className="container">
          <div className="row">
            <h2 className="contact-heading">Our Contact</h2>
            <p className='text-white'>Get in touch with me.</p>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row py-2 heading">
          <div className="col-md-12 text-center py-4 pt-5">
            <h2
              className="contact-head text-black"
              style={{ fontWeight: "bold" }}
            >
              Contact Us
            </h2>
            <span
              style={{ border: "1px solid red", height: "0px", width: "130px" }}
              className="mx-auto d-block"
            ></span>
            <span
              style={{ border: "1px solid red", height: "0px", width: "65px" }}
              className="mx-auto d-block mt-1"
            ></span>
          </div>
        </div>
        <div className="row mb-4 mt-3">
          <div className="col-md-7 contact">
            <div className="row text-black">
              <div className="col-md-12" style={{ lineHeight: "1.8rem" }}>
                <p>Contact Address</p>
                <i className="fa-solid fa-location-dot"></i>
                <span> KIEZEN SOFT TECH</span>
                <br />
                <span>Shahi Apartment 3rd Floor, Mahmoorganj, Varanasi-221010</span>
                <br />
                <i className="fa-solid fa-mobile-screen"></i>
                <span>+91-9565566662</span>
                <br />
                <i className="fa-regular fa-envelope"></i>
                <span>sumit.iti.sri@gmail.com</span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 mt-5 py-2">
                <iframe
                  title="map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3607.0200916418225!2d82.97901971004484!3d25.303529027294086!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e3208731a675b%3A0x5774824a391afcc9!2sKieZen%20Soft%20Tech!5e0!3m2!1sen!2sin!4v1686988965401!5m2!1sen!2sin"
                  width="100%"
                  height="250"
                  style={{ border: "0", marginRight: "30px" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </div>
          <div className="col-md-5 contact-form-details">
            <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>
              Contact Form
            </h5>
            <form className="contact-form">
              <div className="mt-3">
                <label htmlFor="name" className="form-label">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="form-control h-25"
                  valid="true"
                  required
                />
              </div>
              <div className="mt-3">
                <label htmlFor="email" className="form-label">
                  Your Email
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  className="form-control"
                  valid="true"
                  required
                />
              </div>
              <div className="mt-3">
                <label htmlFor="sub" className="form-label">
                  Subject
                </label>
                <input
                  type="text"
                  name="sub"
                  id="sub"
                  className="form-control"
                  valid="true"
                  required
                />
              </div>
              <div className="mt-3">
                <label htmlFor="messege" className="form-label">
                  Your messege
                </label>
                <br />
                <textarea  placeholder="Enter message" className="w-100" valid="true" required/>
              </div>
              <div className="mt-3">
                <button type="submit" className="btn btn-outline-primary">
                  Send
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
