import React from "react";
import "./Products.css";
import FontAwesome from "react-fontawesome";

export default function Products() {
  return (
    <div className="container-fluid g-0">
      <div className="products-bg">
        <div className="container">
          <div className="row">
            <h2 className="product-head">Our Products</h2>
            <p className='text-white'>How we made things easier.</p>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <h2 className="product1">Myeatery</h2>
        </div>
        <div className="row">
          <div className="col-lg-8">
            <p className="product-para">
              <font size="+1" style={{ color: "orange" }}>
                Myeatery
              </font>
              is a technology solution that enables restaurateurs to efficiently
              manage their operations. We cater to any F&B establishment's front
              and back-end house affairs as a B2B arm of Dineout.
              <font size="+1" style={{ color: "orange" }}>
                Myeatery
              </font>
              aims to disrupt the restaurant industry and put an end to the
              problems that have plagued restaurateurs since the dawn of time
              through innovative and integrated solutions.
            </p>
            <div className="list">
              <ul>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Myeatery App (Customer)
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Myeatery App (Delivery Boy)
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Myeatery Admin Panel
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Myeatery Restaurants Panel
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 myeatery-anim">
            <img src="Image/myeatery.png" alt="Eatery" />
          </div>
        </div>
      </div>
    </div>
  );
}
