import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

export default function Navbar() {
  return (
    <>
    <div className="container-fluid">
        <div className="row p-1 start">
            <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="row">
                    <div className="col-sm-6 text-center">
                        <Link to="tel:+91-%209889357789"><span className="fa fa-phone-alt"></span>&nbsp;&nbsp; Call-us
                            +91-9565566662</Link>
                    </div>
                    <div className="col-sm-6 text-center">
                        <Link to="mailto:msapan80@yahoo.com"><i className="fa-regular fa-envelope"></i>&nbsp;&nbsp;
                            sumit.it.sri@gmail.com</Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ps-5 text-center site">
                <Link to="https://www.facebook.com/"><i className=" fa-brands fa-facebook-f"></i></Link>
                <Link to="https://twitter.com/"><i className="fa-brands fa-twitter"></i></Link>
                <Link to="https://www.google.com/"><i className="fa-brands fa-google-plus-g"></i></Link>
            </div>
        </div>
    </div>
    <nav className="navbar navbar-expand-lg  sticky-top">
        <div className="container-fluid">
        <Link to='/' className='navbar-brand'>
                <span className='kiezenlogo'>Kiezen Soft Tech</span>
            </Link>
            <button className="navbar-toggler btn btn-outline-info btn-sm ms-auto" type="button"
                style={{backgroundColor: "orange", color: "white"}} data-bs-toggle="collapse"
                data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse " id="navbarResponsive">
                <ul className="navbar-nav ml-auto mynav">
                    <li className="nav-item" >
                        <Link to='/'className='item'>Home</Link>
                    </li>
                    <li className="nav-item">
                    <Link to='/products' className='item'>Products</Link>
                    </li>
                    <li className="nav-item">
                    <Link to='/about-us' className='item'>About Us</Link>
                    </li>
                    <li className="nav-item">
                    <Link to='/services' className='item'>Services</Link>
                    </li>
                    <li className="nav-item">
                    <Link to='/career' className='item'>Career</Link>
                    </li>
                    <li className="nav-item">
                    <Link to='/contact' className='item'>Contact</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    </>
  )
}
