import React from "react";
import "./Services.css";
import FontAwesome from "react-fontawesome";

export default function Services() {
  return (
    <div className="container-fluid g-0 main">
      <div className="services-bg">
        <div className="container">
          <div className="row">
            <h2 className="service-heading">Our Services</h2>
            <p className='text-white'>A lot more than making websites.</p>
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-4 mt-5">
            <img src="Image/services1.jpg" alt="Web Development" />
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-7 mt-5">
            <h2 className="service1">Web Development</h2>
            <p className="service-para">
              Web development involves developing websites and platforms for the
              internet. This is the most trending and common way to publicize
              your work and create a reach to the people of the world. In
              today’s time be it any kind of organisation such as schools,
              colleges, hospitals, officials, management etc all of them need a
              way to be recognized by the audience and come in sight of the
              audience. Because up to how long would traditional market will
              take far .In today’s digital era all of us as a customer go to
              internet to look up for the best choices for all of our
              requirements. Web development in itself is not a single task done
              or a single goal achieved .It include many other step and process
              to make your product stand tall on internet. This process include
              web design, web content writing , ui/ux development , Technologies
              used for Web Developmen client side/server side scripting ,
              security integration etc.
            </p>
            <div className="service-list">
              <ul>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Angular
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;React
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;PHP
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;ASP.Net
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;MVC
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-app">
      <div className="container">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="service2">Mobile App Development</h2>
            <p className="service-para text-white">
              <font size="+2" style={{ color: "orange",fontStyle:"italic" }}>
                Kiezen Soft Tech
              </font>&nbsp;
              deploys its mobile app development services to resolve the
              problems of the clients. No matter how complex the problem is, the
              technical team is always on the move probing, researching and
              finding the best solution. Prior to starting development, software
              experts collaborate with the clients to understand the
              requirements in detail. By adopting the prototyping model, we have
              to minimize reduced the latency time and increased the speed of
              the application development..
            </p>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-4 service2-image">
            <img
              src="Image/services2.png"
              className="w-100"
              // style={{ width: "400px" }}
              alt="Mobile App"
            />
          </div>
        </div>
      </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-lg-5 service3-image">
            <img
              src="Image/services3.png"
              className="w-100"
              // style={{ width: "400px" }}
              alt="Software"
            />
          </div>
          <div className="col-lg-7">
            <h2 className="service3">Software Development</h2>
            <p className="service-para">
              Software development is the process of conceiving, specifying,
              designing, programming, documenting, testing, and bug fixing
              involved in creating and maintaining applications, frameworks, or
              other software components.
              <br />
              <br />
              <span className="fw-bold">
                We developed various types of Software:-
              </span>
            </p>
            <div className="service-list">
              <ul>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;GST Software
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;School Software
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Inventory Software
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Shop Billing Software
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Agency Software
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Hotel Software
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Medical Software
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Hospital Software
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Pharma Software
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="hosting">
      <div className="container ">
        <div className="row">
          <div className="col-lg-7">
            <h2 className="service4 text-white">Hosting and Domain</h2>
            <p className="service-para text-white">
              <font size="+2" style={{ color: "orange",fontStyle:"italic" }}>
                Kiezen Soft Tech
              </font>{" "}
              offers Commercial Web Hosting Services in Varanasi that are fully
              secure, extremely reliable and completely scalable, besides being
              cheap & affordable. Web Hosting Services in Varanasi that serves
              every business requirement. We specialise in providing affordable
              and custom solutions to the challenges of Internet infrastructure
              and requirements of hosting. Our widespread customer base is
              leveraged in order to attain hosting capacity at cost effective
              prices and make us one of the best web hosting solution providing
              company in India We provide domain registration service
              <br />
              <br />
              <span className="fw-bold">
                Features of Our Web Hosting Services:-
              </span>
            </p>
            <div className="service-list text-white">
              <ul>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Effectual load balancing for faster performance &
                  better reliability.
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;E-mail & Database Support.
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Enough space and bandwidth for your website.
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;Control panel software to create new email
                  accounts with complete administrative rights.
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;Daily backup of your data on a hard storage media.
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;We utilise state- of- art equipments for web
                  hosting, which are rapidly updated to provide the competitive
                  edge.
                </li>
                <li>
                  <FontAwesome name="icon" className="fa-regular fa-hand-point-right text-primary" />
                  &nbsp;&nbsp;We have reliable facilities and hardware which
                  ensures that your website is functioning flawlessly 24/7.
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 service4-image">
            <img
              src="Image/services4.png"
              className="w-100"
              // style={{ width: "400px" }}
              alt="Hosting"
            />
          </div>
        </div>
      </div>
      </div>
    </div>
  );
}
