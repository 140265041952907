import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useState } from "react";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Products from "./pages/Products/Products";
import Services from "./pages/Services/Services";
import Contact from "./pages/Contact/Contact";
import Career from "./pages/Career/Career";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Popup from "./components/Popup/Popup";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [success, setSuccess] = useState(false);


  const onSubmitClick = (e) => {
    e.preventDefault();
    setSuccess(!success);
    setIsOpen(!isOpen);
  };

  const onAgainSendClick=()=>{
    setIsOpen(!isOpen);
    setSuccess(!success);
  }

  const onClickShow=()=>{
    if(success){
      setSuccess(!success);
    }
    else{
      setIsOpen(!isOpen)
    } 
  }
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Popup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        success={success}
        setSuccess={setSuccess}
        onSubmitClick={onSubmitClick}
        onAgainSendClick={onAgainSendClick}
        onClickShow={onClickShow}
      />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/products" element={<Products />} />
        <Route path="/services" element={<Services />} />
        <Route path="/career" element={<Career />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
